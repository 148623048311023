<template id="istoric">
  <div class="istoric">
    <div class="content">
      <el-card class="box-card">      
        <el-collapse v-model="active" accordion style="border: none">
          <el-collapse-item name="1" style="border: none">
            <template slot="title">
              <div slot="header" class="clearfix header">
                <span class="title">{{Titlu}}</span>
              </div>
            </template>
              <div class="filtre">
                <el-form style="display:flex;align-items:center" @submit.prevent.native="refresh_info()" label-position="top">
                  <el-row style="margin: 10px" class="input-group">
                    <el-col :sm="23" class="input-element">
                      <el-form-item label="Perioada">
                          <el-date-picker
                            v-model="excelPeriod"
                            @change="handleDateChange"
                            type="daterange"
                            :picker-options="datePickerOptions"
                            range-separator="-"
                            start-placeholder="Data inceput"
                            end-placeholder="Data sfarsit"
                            size="small"
                            format="dd/MM/yyyy"
                            value-format="yyyy-MM-dd"
                            clearable>
                          </el-date-picker>
                      </el-form-item>
                    </el-col> 
                    <el-col :sm="1">
                      <el-form-item label="Export">
                        <el-button @click="exportHandler"  icon="el-icon-s-promotion" size="small" type="info" circle></el-button>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
          </el-collapse-item>
        </el-collapse>
        <el-table
          width="1000px"
          style="height:450px;overflow-y: scroll"
          :data="Rezervari"
          size=small
        >
        <el-table-column width="70px" label="Id deviz">
          <template slot-scope="scope">
            #{{ scope.row.Id }}
          </template>
        </el-table-column>
          <el-table-column width="145px" label="Data terminare lucru">
            <template slot-scope="scope">
              {{scope.row.DataTerminareLucru | DataOraFaraSec}}
            </template>
          </el-table-column>
          <el-table-column width="95px" prop="Numar" label="Nr. masina"/>
          <el-table-column width="90px" prop="Marca" label="Marca"/>
          <el-table-column width="90px" prop="Model" label="Model"/>
          <el-table-column width="50px" prop="An" label="An"/>
          <el-table-column width="75px" prop="Km" label="Km"/>
          <el-table-column min-width="150px" label="Client (utilizator)">
            <template slot-scope="scope">
              {{scope.row.NumeClient}} {{scope.row.PrenumeClient}}
            </template>
          </el-table-column>
          <el-table-column width="90px" prop="Valoare" label="Total deviz"/>
          <el-table-column min-width="250px" prop="EmailClient" label="Email"/>
          <el-table-column align="center" prop="Deviz" label="Deviz">
            <template slot-scope="scope">
              <el-button
                plain
                round
                size="mini"
                @click="download_deviz(scope.row.Id)"
                :class="scope.row.Deviz != '' ? 'ok' : 'eroare'"
                :disabled="scope.row.Deviz != '' ? false : true"
              >
                <!-- {{ scope.row.Deviz !== "" ? "Download" : "Indisponibil" }} -->
                <template>
                  <i class="el-icon-download"></i>
                </template>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="Factura" label="Factura">
            <template slot-scope="scope">
              <el-button
                plain
                round
                size="mini"
                @click="download_factura(scope.row.IdFactura)"
                :class="(scope.row.IdFactura === '' || scope.row.IdFactura == null) ? 'eroare' : 'ok'"
                :disabled="(scope.row.IdFactura === '' || scope.row.IdFactura == null) ? true : false"
              >
                <!-- {{ (scope.row.Factura === "" || scope.row.Factura == null) ? "Indisponibil" : "Download" }} -->
                <template>
                  <i class="el-icon-download"></i>
                </template>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
  </div>
</template>

<script>
  import settings from '../backend/LocalSettings.js'
  import moment from "moment";
  import TitluPagina from "@/widgets/TitluPagina";

export default {
  name: "Istoric",
  components:{
    'titlu-pagina':TitluPagina
  },
  data() {
    return {
      Titlu: "",
      selectedObject: {},
      Rezervari: [],
      active:'0',
      excelPeriod: [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      datePickerOptions:{},
    };
  },

  methods: {
    download_deviz: async function(Deviz) {
      let args = {CheckSender:settings.get_key(), Deviz: Deviz }
      let url = "https://service.anvelopeinstoc.ro/api/index.php/test_api/download_deviz"; // /api pt dev
      let response = await this.$http.post(url, args);
      let window_deviz = window.open("Deviz", "_blank")
      await window_deviz.document.write(response.body.html);
      

    },

    download_factura: async function(Factura) {
      let url = "https://service.anvelopeinstoc.ro/api/index.php/test_api/download_factura"; // /api pt dev
      let args = {Id: Factura, CheckSender:settings.get_key() }
      let response = await this.$http.post(url, args, {
        responseType: 'blob'
      });
      
      const blob = new Blob([response.data]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `Factura # ${Factura}.pdf`;
      link.click();


    },

    go_back: async function () {
      this.$router.push("/");
    },

    async exportHandler(){
      try{
        const export_excel = "https://service.anvelopeinstoc.ro/api/index.php/test_api/export_excel"; // /api pt dev
        const args = { secure_email: this.secure_email, perioada: this.excelPeriod,CheckSender:settings.get_key()};
        const response = await this.$http.post(export_excel, args,{
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const blob = new Blob([response.body], { type: 'application/vnd.ms-excel' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = "Export istoric.xls";

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
            
      }catch(error){
        console.error(error)
      }
    },

    refresh_info: async function () {
      this.selectedObject = {
        Programari: [],
      };
      this.secure_email = this.$route.params.secure_email;
      let api_programari = "https://service.anvelopeinstoc.ro/api/index.php/test_api/get_istoric"; // /api pt dev
      let args = { secure_email: this.secure_email, CheckSender:settings.get_key(), perioada: this.excelPeriod };
      let response = await this.$http.post(api_programari, args);
      let result = response.body.result;
      
      this.Titlu = `${response.body.Titlu}`;
      this.Rezervari = result;
        

        // result.forEach((element) => {
        //   this.selectedObject.Programari.push({
        //     DataRezervarii: moment(element.DataRezervarii).format("DD/MM/YYYY HH:mm"),
        //     Numar: element.Numar,
        //     EmailClient: element.EmailClient,
        //     Client: element.Client,
        //     Deviz: {
        //       Id: element.Id,
        //       Numar: element.Numar,
        //       NumeClient: element.NumeClient + " " + element.PrenumeClient,
        //       NumeMecanic: element.NumeMecanic,
        //       Produse: [
        //         {
        //           Nume: "test",
        //           Cantitae: 10,
        //           Valoare: 10

        //         }
        //       ],
        //       Total: "10",
        //     },
        //     Factura: element.IdFactura,
        //   });
        // });
    },
    handleDateChange(dates) {
      if (dates && dates.length === 2) {
        const [start, end] = dates;

        this.convertStartEndHours(start,end)
        this.refresh_info();
      }else if(!dates || dates.length === 0){
        this.refresh_info();
      }
    },
    convertStartEndHours(start,end){
      const startDate = new Date(start);
      const endDate = new Date(end);

      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);

      this.excelPeriod = [
        this.formatDateForValue(startDate),
        this.formatDateForValue(endDate)
      ];

    },
    formatDateForValue(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
  },
  mounted() {
    this.active = '1';
    this.convertStartEndHours(moment().startOf('month').format('YYYY-MM-DD'),moment().format('YYYY-MM-DD'))
    this.datePickerOptions = settings.datePickerOptions;

    this.refresh_info();
  },
};
</script>


<style lang="less" scoped>
    @gri: #F1F2F2;
    @gri_inchis: #8e97b3;
    @albastru_transparent:rgba(76, 100, 232, 0.445);
    @albastru: #4C63E8;
    @verde: #4C63E8;
    @rosu-hover: #F7E8E8;
    @rosu: #ec7376;
    @rosu_deschis: #F7E8E8;
    @galben: #FEF6CB;
    @galben_wallpaper: #FCFEF1;
    @galben_inchis: #E1D588;
    @liber: #caffc4;
    @liber_text: #185210;
    @ocupat: #f3f5f9;
    @ocupat_text: #b3bbca;
    @zi_indisponibila: #f7e9e9;
    @zi_indisponibila_text: #752f2f;
    .ok {
        width: 30px;
        height: 30px;
        margin: 0;
        padding: 3px;
        text-align: center;
        border-radius: 50%;
        background-color: @albastru;
        color: white;
    }
    .eroare {
        width: 30px;
        height: 30px;
        margin: 0;
        padding: 3px;
        text-align: center;
        border-radius: 50%;
        background-color: @rosu;
        color: white;
    }


    .istoric {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }
      .content {
        width: 85%;
        height: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        border-radius: 5px;
        background: transparent;
        // box-shadow: 0px 2px 4px #00000033, 0px 20px 50px #00000066;
    }

.title {
    color: @albastru;
    font-size: 24px;
    font-weight: 500;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box-card {
  width: 95%;
  height: 90%;
  overflow: hidden;
  // overflow-y: scroll;
  .el-table {
    overflow-y: scroll !important;
  }
}
</style>